/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "customized-hearing-solution-without-the-wait",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customized-hearing-solution-without-the-wait",
    "aria-label": "customized hearing solution without the wait permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customized Hearing Solution Without the Wait"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Changes occur in the brain when you acquire hearing loss. Auditory neurons need continuous input to stay healthy. For that reason, many hearing care professionals recommend treating hearing loss sooner rather than later. On average, people wait about seven years to seek an evaluation after they start to notice hearing difficulties. That’s too long."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When people do make the appointment at the hearing care professional’s office, they often have a special occasion that motivates them to seek help. Perhaps they want to hear at a wedding or graduation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Normally, if you want a ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "custom-fit hearing aid"), ", you have a hearing care professional take an impression of your ears. This impression is mailed to a lab. A technician uses the impression to make the shell of your hearing aid. The completed hearing aid can take as long as two weeks or more to arrive at the hearing care professional’s office. This process takes too long if you have a special event coming up next week."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Rexton inox hearing aid is a discreet ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "completely in the canal (CIC)"), " hearing aid that can be fit to your ears in the office using plastic sleeves. These sleeves come in different sizes and click on to the end of the hearing aid. The CIC size means that the hearing aid will fit into your ear canal opening. Most people will not notice that you are wearing a hearing aid."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing care professional programs the hearing aids for you in the office using the computer. The hearing aids’ computer chip stores information about your hearing loss and the settings you prefer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you want to insert your hearing aids, simply look at the shell. The red shell designates a right hearing aid, and the blue shell designates a left hearing aid. Your hearing care professional will show you the correct orientation. Simply push the hearing aids into your ears and you are good to go."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "technology-and-options-personalized-to-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#technology-and-options-personalized-to-you",
    "aria-label": "technology and options personalized to you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Technology and Options Personalized to You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aids can contain multiple listening programs tailored to different situations that you encounter. You may change the volume, program, and tone settings using the Smart Remote app on your cell phone. If you do not have a smart phone, you can purchase a Smart Key remote control."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with hearing loss have more problems processing sounds. They cannot handle noisy environments as well as people with normal hearing. In noisy listening situations, the Voice Ranger technology works to enhance speech understanding and reduce annoying background noise. Depending on the technology level that you purchase, the hearing aids may communicate with each other to improve their ability to separate speech from noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Shopping malls with hard floors, furniture, and walls can cause echoes. People with hearing loss have more difficulty than people with normal hearing in separating out sound from reverberations. The Reverb Reducer technology helps to minimize reverberations for clearer sound."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Typical hearing aid programs focus on maximizing your ability to understand speech. The processing can introduce artifacts into music. Rexton’s Music Enhancer technology provides you with a fuller experience of your favorite sound tracks by automatically adjusting to a music listening mode."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many people with hearing loss report that they hear ringing (tinnitus) in their ears. Hearing aids can help make your tinnitus less bothersome by masking the sound. Rexton offers a tinnitus function that can be added to your hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Traditionally, the smallest hearing aids could not fit Bluetooth technology. The inoX hearing aids are ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth compatible"), ", allowing you to listen directly to your cell phone and other audio devices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some individuals have one ear with close to normal hearing, and one ear that has severe to profound hearing loss. Unilateral hearing loss makes it difficult to tell where sounds come from. These patients may benefit from a ", React.createElement(_components.a, {
    href: "/hearing-aids/types/cros-bicros",
    className: "c-md-a"
  }, "CROS hearing aid that sends sound from the poorer ear to the better ear"), ". The Rexton inoX offers a CROS fitting option."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Inox now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-trusted-advisor-for-your-hearing-journey",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-trusted-advisor-for-your-hearing-journey",
    "aria-label": "a trusted advisor for your hearing journey permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Trusted Advisor for Your Hearing Journey"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing experts at hear.com will support you on the journey to address your hearing difficulties. Sign up today at hear.com in order to start your 45 day risk-free trial. Our expert will call you to answer your questions and schedule you an appointment with a hearing care professional. The hearing care professional will perform a complete hearing evaluation and determine if Rexton inoX hearing aids are right for you. You will have 45 days to try the hearing aids risk-free. If you are not satisfied, you will receive a full refund."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hear.com expert will assist you throughout the entire process. Financing options are available if you need assistance with paying for your hearing aids."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Rexton Inox now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
